<template>
  <v-card :min-height="minHeight">
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `імпорт файла монетизації ${getMonetaryTypeTranslate(this.monetary_type)}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-3">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field filled
                          :value="isDebt ? 'Подання інформації' : 'Реєстр відшкодованих сум'"
                          readonly
                          label="Операція"
                          hide-details
                          color="grey"
            />
          </v-col>
          <v-col cols="12">
            <v-select filled :items="monetaryTypeSelect"
                      v-model="monetary_type"
                      :rules="[v => !!v || 'Обов’язково оберіть файл']"
                      hide-details
                      color="grey"
                      label="Вид монетизації"
            />
          </v-col>
          <v-col cols="12">
            <v-file-input
                show-size
                filled
                color="grey"
                label="Файл для обробки"
                hide-details
                v-model="file"
                prepend-icon=""
                placeholder="Оберіть файл на вашому пристрої"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :rules="[value => value !== null || 'Це поле є обвов’язковим']"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Завантажити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import {QUESTION_SHOW} from "@/store/actions/question";
import monetaryAPI from "@/utils/axios/monetary";
import {ALERT_SHOW} from "@/store/actions/alert";
import {formatDate} from "@/filters";
import {getMonetaryTypeTranslate, monetaryTypeSelect} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'monetary_debt_modal_delete'

export default {
  name: "HWP_Modal_MonetaryFileImport",
  mixins: [ModalComponentMixin],
  props: ['minHeight'],
  data() {
    return {
      monetary_type: this.item.monetary_type || null,
      isDebt: this.item.isDebt,
      file: null,
      menu: false,
      monetaryTypeSelect
    }
  },
  methods: {
    formatDate,
    getMonetaryTypeTranslate,
    closeModal() {
      this.$emit('closeModal')
      this.monetary_type = this.item.monetary_type || null
      this.isDebt = this.item.isDebt

      this.file = null
      this.monetary_type = null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.$emit('closeModal')
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення банку № ${this.name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Вид монетизації, Файл - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        monetary_type: this.monetary_type,
        isDebt: this.isDebt
      }
      const formData = new FormData()
      formData.append('config', JSON.stringify(payload))
      formData.append('file', this.file)


      if (this.isDebt) {
        monetaryAPI.importFile(formData)
            .then(response => response.data)
            .then(() => {
              this.$emit('crud', Object.assign({}, {method: 'fetch', isDebt: this.isDebt}))
              this.closeModal()
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      } else {
        monetaryAPI.importFilePay(formData)
            .then(response => response.data)
            .then(() => {
              this.$emit('crud', Object.assign({}, {method: 'fetch', isDebt: this.isDebt}))
              this.closeModal()
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.monetary_type = payload.monetary_type || null
              this.isDebt = payload.isDebt
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch('REMOVE_BANK', this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
    }),
    filtered_items() {
      return this.items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
    }
  }
}
</script>

<style scoped lang="scss">
.table-header {
  opacity: .8;

  .table-col:nth-child(1) {
    //border-left: 4px solid transparent;
  }
}

.table-body {
  .table-row {
    height: 25px !important;
    //border-left: 4px solid #4caf50d1;
    margin-bottom: 1px;
    background-color: rgba(243, 243, 243, 0.5);

    &:hover {
      transition: 90ms all ease-in;
      background-color: rgba(199, 199, 199, 0.3);
    }

    .table-col {
      overflow: hidden;
      //white-space: pre;
      align-self: center;
      text-overflow: ellipsis;
      font-size: .82rem !important;
      background-color: transparent !important;
      word-wrap: break-word;
      white-space: nowrap;
    }
  }
}

input {
  width: 90%;
  display: block;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, .4);
  padding: 2px 4px 2px 0;
  transition: all .2s ease-in-out;

  &:focus {
    border-color: var(--v-success-base);
    font-weight: 500;
  }

  //&[disabled] {
  //  border-bottom: 2px dotted rgba(0, 0, 0, .4);
  //  background-color: #f1f1f1;
  //}
}
.table-col {
  :deep(.error-input) {
    input {
      border-bottom: 1px solid #d76662 !important;
    }
  }
}

</style>